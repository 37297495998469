<template>
  <div class="registration-confirm-change-reservation">
    <div class="page-title">
      <base-page-title>{{
        $t('registrationConfirmChangeReservation.pageTitle')
      }}</base-page-title>
    </div>
    <div class="reservation-progress-bar">
      <progress-bar v-bind="progressBarData" />
    </div>
    <div class="reservation-confirm">
      <information-list-login-reservation
        :reservation-info-list="reservationInfoList"
      />
    </div>
    <div class="next-button">
      <base-decision-button @click="fetchReservations" :disabled="waitFlg">{{
        $t('registrationConfirmChangeReservation.buttonNext')
      }}</base-decision-button>
    </div>
    <div class="back-button">
      <base-cancel-button @click="backPage">{{
        $t('registrationConfirmChangeReservation.buttonBack')
      }}</base-cancel-button>
    </div>
    <announce-popup
      v-if="alertFlg"
      @close="alertFlg = false"
      :title="$t('common.error')"
      :buttons="buttons"
      :type="'failure'"
      >{{ popupMessage }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import InformationListLoginReservation from '@/components/parts/organisms/InformationListLoginReservation.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'
import { getAxiosObject } from '@/utils/library'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import { reservationErrMsg } from '@/utils/reservation_error'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import { createProgressBarDataForReservation } from '@/utils/createProgressBarData'

export default {
  name: 'RegistrationConfirmChangeReservation',
  components: {
    BasePageTitle,
    ProgressBar,
    InformationListLoginReservation,
    BaseDecisionButton,
    BaseCancelButton,
    AnnouncePopup,
    UnsavedLeavePopup
  },
  mixins: [ReloadConfirm],

  data() {
    return {
      progressBarData: {},
      reservationInfoList: [],
      scenario: this.$store.getters['auth/scenario'],
      owner: this.$store.getters['owner/getOwner'],
      patients: this.$store.getters['patient/getPatients'],
      newPatients: this.$store.getters['patient/getNewPatients'],
      species: this.$store.getters['species/getDataIncludeDelData'],
      newReservation: this.$store.getters['reservation/getNewReservation'],
      changeReservation: this.$store.getters[
        'reservation/getChangeReservation'
      ],
      alertFlg: false,
      popupMessage: '',
      buttons: [this.$t('common.close')],
      waitFlg: false
    }
  },

  computed: {
    tgtReservation() {
      return this.scenario === 'new_reservation'
        ? this.newReservation
        : this.changeReservation
    }
  },

  created() {
    this.progressBarData = createProgressBarDataForReservation(this.scenario, 5)
    this.reservationInfoList = this.tgtReservation.patientsIds
      .map(v => this.patients.find(patient => patient.id === v))
      .concat(
        this.newPatients.filter(np => np.name !== '' && np.speciesId !== '')
      )
      .map((patient, i) => {
        const species = this.species.find(v => v.id === patient.speciesId)
        return {
          id: i + 1,
          purpose: this.tgtReservation.reservationPurposeName,
          purposeEnglish: this.tgtReservation.reservationPurposeNameEnglish,
          date: this.tgtReservation.date,
          time: this.tgtReservation.startTime,
          ownerLastName: this.owner.lastName,
          ownerFirstName: this.owner.firstName,
          ownerLastNameKana: this.owner.lastNameKana,
          ownerFirstNameKana: this.owner.firstNameKana,
          species: species.name,
          speciesEnglish: species.nameEnglish,
          breed: patient.breed,
          patientName: patient.name,
          tel: this.owner.tel,
          email: this.owner.email,
          memo: this.tgtReservation.memo
        }
      })
  },

  methods: {
    fetchReservations() {
      this.scenario === 'new_reservation'
        ? this.fetchNewReservations()
        : this.fetchChangeReservations()
    },
    async fetchNewReservations() {
      this.waitFlg = true
      try {
        const axiosObject = getAxiosObject()
        const response = await axiosObject.post('/reservations/', {
          newReservation: this.newReservation,
          newPatients: this.newPatients
        })
        this.$store.dispatch('patient/addPatients', response.data.patients)
        this.$store.dispatch(
          'reservation/setReservations',
          response.data.reservations
        )
        this.$store.dispatch(
          'reservationRequests/setData',
          response.data.reservationRequests
        )
        this.$store.dispatch('patient/resetNewPatients')
        this.$store.dispatch('reservation/resetNewReservation')
        this.$router.push({ name: 'ReservationRegistered' })
      } catch (ex) {
        this.alertFlg = true
        this.waitFlg = false
        if (ex.response?.status === 401) {
          this.buttons = [this.$t('common.buttonToLogin')]
        } else {
          const errorMessage = reservationErrMsg(ex, this.scenario)
          this.popupMessage = this.$t(
            `registrationConfirmChangeReservation.${errorMessage}`
          )
          this.buttons = [this.$t('common.close')]
        }
      }
    },
    async fetchChangeReservations() {
      this.waitFlg = true
      try {
        const axiosObject = getAxiosObject()
        const response = await axiosObject.put('/reservations/', {
          changeReservation: this.changeReservation,
          newPatients: this.newPatients
        })
        this.$store.dispatch('patient/addPatients', response.data.patients)
        this.$store.dispatch(
          'reservation/setReservations',
          response.data.reservations
        )
        this.$store.dispatch(
          'reservationRequests/setData',
          response.data.reservationRequests
        )
        this.$store.dispatch('reservation/resetChangeReservation')
        this.$store.dispatch('patient/resetNewPatients')
        this.$router.push({ name: 'ReservationRegistered' })
      } catch (ex) {
        this.alertFlg = true
        this.waitFlg = false
        if (ex.response?.status === 401) {
          this.buttons = [this.$t('common.buttonToLogin')]
        } else {
          const errorMessage = reservationErrMsg(ex, this.scenario)
          this.popupMessage = this.$t(
            `registrationConfirmChangeReservation.${errorMessage}`
          )
          this.buttons = [this.$t('common.close')]
        }
      }
    },
    backPage() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.registration-confirm-change-reservation {
  > .page-title {
    margin-top: 70px;
  }
  > .reservation-progress-bar {
    margin: 28px 0 61px;
  }
  > .next-button {
    margin-top: 80px;
  }
  > .back-button {
    margin: 23px 0 80px 0;
  }
}
@media (max-width: $global-media-width) {
  .registration-confirm {
    > .reservation-progress-bar {
      margin-bottom: 66px;
    }
    > .next-button {
      margin-top: 83px;
    }
  }
}
</style>
