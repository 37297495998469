const defaultErrMsg = error => {
  if (
    error.response?.data?.extra ===
    'reservation error because reservation reception invalid'
  ) {
    return 'errorReservationUnavailable'
  } else if (
    error.response?.data?.extra ===
    'reservation error because too many reservations'
  ) {
    return 'errorReservationLimitExceeded'
  } else if (
    error.response?.data?.extra ===
    'reservation error because patients is exceeded'
  ) {
    return 'errorPatientLimitExceeded'
  } else if (
    error.response?.data?.extra ===
      'reservation error because not enough staff' ||
    error.response?.data?.extra ===
      'reservation error because not enough column'
  ) {
    return 'errorUnavailableTime'
  } else if (
    error.response?.data?.extra ===
    'reservation error because outside reception period'
  ) {
    return 'errorInvalidDate'
  } else if (
    error.response?.data?.extra ===
    'reservation error because outside reception hours'
  ) {
    return 'errorInvalidTime'
  } else if (error.response?.data?.extra === 'reservationPurposes') {
    return 'errorInvalidReservationPurpose'
  } else if (error.response?.data?.extra === 'owner') {
    return 'errorOwnerDeleted'
  } else if (error.response?.data?.extra === 'blank memo') {
    return 'errorMemoRequired'
  } else {
    return 'errorRegistrationFailed'
  }
}

export const reservationErrMsg = (error, scenario) => {
  if (scenario === 'first_reservation') {
    if (
      error.response?.data?.extra ===
      'reservation error because first reservation reception invalid'
    ) {
      return 'errorUnavailable'
    } else {
      return defaultErrMsg(error)
    }
  } else if (scenario === 'new_reservation') {
    if (
      error.response?.data?.extra ===
      'reservation error because owner reservation reception invalid'
    ) {
      return 'errorUnavailable'
    } else {
      return defaultErrMsg(error)
    }
  } else if (scenario === 'change_reservation') {
    if (
      error.response?.data?.extra ===
      'reservation error because owner reservation reception invalid'
    ) {
      return 'errorUnavailable'
    } else if (
      error.response?.data?.extra ===
      'reservation error because reservation change reception invalid'
    ) {
      return 'errorChangesUnavailable'
    } else if (error.response?.data?.extra === 'reservation') {
      return 'errorRegistrationUpdateFailed'
    } else if (error.response?.data?.extra === 'karte exists') {
      return 'errorContactClinic'
    } else {
      return defaultErrMsg(error)
    }
  } else {
    return
  }
}
